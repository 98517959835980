.pdftron {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .webviewer {
    height:100%;
  }
  .header {
    width: 100%;
    height: 60px;
    padding: 8px 8px 8px 16px;
    box-sizing: border-box;
    background: #00a5e4;
    font-size: 1.2em;
    line-height: 44px;
    color: white;
  }
}
